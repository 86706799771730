import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    return(
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="FAQ">
        <div>
          <h1>FAQ</h1>
          <div style={styles.infoContainer}>
            <h4>1. How long will it take me to improve?</h4>
            <p>This is a difficult question to answer because every person is different. Some people 
              feel that they need only a small number of sessions and other people feel that more than 
              a small number of sessions is beneficial.</p>
            <h4>2. Can you tell me what to do to solve my problem?</h4>
            <p>The best solution to a problem is the one that you find for yourself and my role is to 
              support you along this path of self discovery.</p>
            <h4>3. Do I come at the same time every week?</h4>
            <p>Yes.</p>
            <h4>4. How do I know if psychotherapy is right for me?</h4>
            <p>Sometimes a person may be able to call on friends and family for support in times of need, 
              but if that isn’t enough then maybe a counsellor/psychotherapist may be a good option.</p>
            <h4>5. What are your hours of work?</h4>
            <p>I work Monday to Friday and have timeslots available throughout the day and night to accommodate people.</p>
        </div>
      </div>
    </Layout>
    )
  }
}

const styles = {
  infoContainer: {
    overflow: 'auto'
  },
  info: {
    float: 'left',
    margin: '20px 20px',
  },
  mapContainer: {
    display: 'block',
    marginTop: 40
  },
  map: {
    width: '100%',
    height: 400,
    border: 'none'
  }
}

export default FAQ

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`